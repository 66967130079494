<template>
    <div class="table-responsive table-striped my-1">
        <table class="table">
            <thead class="table-light">
                <tr>
                    <th>Date</th>
                    <th>Txn Type</th>
                    <th>Voucher No</th>
                    <th>Account </th>
                    <th>Debit</th>
                    <th>Credit</th>
                    <th>Line Description</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(dayBook,index) in dayBooks" :key="index">
                    <td>
                        {{ dayBook.date }}
                    </td>
                    <td>
                        {{ dayBook.txn_type }}
                    </td>
                    <td>
                        {{ dayBook.voucher_no }}
                    </td>
                    <td>
                        {{ dayBook.account }}
                    </td>
                        <td>
                        {{ commaFormat(dayBook.debit) }}
                    </td>
                        <td>
                        {{ commaFormat(dayBook.credit) }}
                    </td>
                        <td>
                        {{ dayBook.line_description }}
                    </td>
                </tr>
            </tbody>
        </table>
        <p v-if="!dayBooks.length" class="mt-5 text-center">No data</p>
    </div>
</template>

<script>
import figureFormatter from '@/services/utils/figureFormatter'

export default {
    name: 'DaybookTable',
    props: {
        dayBooks: {
            type: Array
        }
    },
    setup() {
        const {commaFormat} = figureFormatter ()

        return {
            commaFormat
        }
    },

}
</script>
